import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <Text>
            <h1 class="c8" id="h.h2wqyu3q3r6q"><span class="c5 c10">{headingText}</span></h1>
            <p class="c6"><span class="c1">Effective date: July 13, 2022</span></p>
            <p class="c6"><span class="c1">ReceiptsIQ.co (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the ReceiptsIQ web and mobile applications (hereinafter referred to as the &quot;Service&quot;).</span></p>
            <p class="c6"><span class="c1">This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.</span></p>
            <p class="c6"><span class="c1">We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</span></p>
            <h2 class="c4" id="h.4n02p9pxxmfe"><span class="c2">Definitions</span></h2>
            <ul class="c12 lst-kix_f4a7zx29ld8r-0 start">
              <li class="c0 li-bullet-0"><span class="c5">Service<br/><br/></span><span class="c1">&nbsp;Service is the ReceiptsIQ web and mobile application operated by ReceiptsIQ.co<br/></span></li>
              <li class="c0 li-bullet-0"><span class="c5">Personal Data<br/><br/></span><span class="c1">&nbsp;Personal Data means data about a living individual who can be identified from the data (or from other information either in our possession or likely to come into our possession).<br/></span></li>
              <li class="c0 li-bullet-0"><span class="c5">Usage Data<br/><br/></span><span class="c1">&nbsp;Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).<br/></span></li>
              <li class="c0 li-bullet-0"><span class="c5">Cookies<br/><br/></span><span class="c1">&nbsp;Cookies are small files stored on your device (computer or mobile device).<br/></span></li>
            </ul>
            <h2 class="c4" id="h.vugcxshs91ff"><span class="c2">Information Collection and Use</span></h2>
            <p class="c6"><span class="c1">We collect several different types of information for various purposes to provide and improve our Service to you.</span></p>
            <h3 class="c3" id="h.5vkg5x71hjq7"><span class="c11 c5">Types of Data Collected</span></h3>
            <h4 class="c7" id="h.6gmtwum9csso"><span class="c9 c5">Personal Data</span></h4>
            <p class="c6"><span class="c1">While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (&quot;Personal Data&quot;). Personally identifiable information may include, but is not limited to:</span></p>
            <ul class="c12 lst-kix_pqhonk9sl1t3-0 start">
              <li class="c0 li-bullet-0"><span class="c1">Cookies and Usage Data</span></li>
            </ul>
            <h4 class="c7" id="h.ci50rl01oft"><span class="c9 c5">Usage Data</span></h4>
            <p class="c6"><span class="c1">When you access the Service with a web or mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data (&quot;Usage Data&quot;).</span></p>
            <h4 class="c7" id="h.iktl5f3folsl"><span class="c5 c9">Tracking &amp; Cookies Data</span></h4>
            <p class="c6"><span class="c1">We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</span></p>
            <p class="c6"><span class="c1">Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</span></p>
            <p class="c6"><span class="c1">You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</span></p>
            <p class="c6"><span class="c1">Examples of Cookies we use:</span></p>
            <ul class="c12 lst-kix_iak19cbwtbd2-0 start">
              <li class="c0 li-bullet-0"><span class="c5">Session Cookies.</span><span class="c1">&nbsp;We use Session Cookies to operate our Service.</span></li>
              <li class="c0 li-bullet-0"><span class="c5">Preference Cookies.</span><span class="c1">&nbsp;We use Preference Cookies to remember your preferences and various settings.</span></li>
              <li class="c0 li-bullet-0"><span class="c5">Security Cookies.</span><span class="c1">&nbsp;We use Security Cookies for security purposes.</span></li>
            </ul>
            <h2 class="c4" id="h.42j8xrhvhxna"><span class="c2">Use of Data</span></h2>
            <p class="c6"><span>ReceiptsIQ.co</span><span class="c1">&nbsp;uses the collected data for various purposes:</span></p>
            <ul class="c12 lst-kix_74qrojcd4nop-0 start">
              <li class="c0 li-bullet-0"><span class="c1">To provide and maintain our Service</span></li>
              <li class="c0 li-bullet-0"><span class="c1">To notify you about changes to our Service</span></li>
              <li class="c0 li-bullet-0"><span class="c1">To allow you to participate in interactive features of our Service when you choose to do so</span></li>
              <li class="c0 li-bullet-0"><span class="c1">To provide customer support</span></li>
              <li class="c0 li-bullet-0"><span class="c1">To gather analysis or valuable information so that we can improve our Service</span></li>
              <li class="c0 li-bullet-0"><span class="c1">To monitor the usage of our Service</span></li>
              <li class="c0 li-bullet-0"><span class="c1">To detect, prevent and address technical issues</span></li>
            </ul>
            <h2 class="c4" id="h.yc0vde9ahm04"><span class="c2">Transfer of Data</span></h2>
            <p class="c6"><span class="c1">Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</span></p>
            <p class="c6"><span class="c1">If you are located outside Canada and choose to provide information to us, please note that we transfer the data, including Personal Data, to Canada and process it there.</span></p>
            <p class="c6"><span class="c1">Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</span></p>
            <p class="c6"><span class="c1">ReceiptsIQ.co will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</span></p>
            <h2 class="c4" id="h.d3i6bauziwsr"><span class="c2">Disclosure of Data</span></h2>
            <h3 class="c3" id="h.ze8fmhfnbbgy"><span class="c5 c11">Legal Requirements</span></h3>
            <p class="c6"><span>ReceiptsIQ.co</span><span class="c1">&nbsp;may disclose your Personal Data in the good faith belief that such action is necessary to:</span></p>
            <ul class="c12 lst-kix_qmu2acimx26a-0 start">
              <li class="c0 li-bullet-0"><span class="c1">To comply with a legal obligation</span></li>
              <li class="c0 li-bullet-0"><span class="c1">To protect and defend the rights or property of ReceiptsIQ.co</span></li>
              <li class="c0 li-bullet-0"><span class="c1">To prevent or investigate possible wrongdoing in connection with the Service</span></li>
              <li class="c0 li-bullet-0"><span class="c1">To protect the personal safety of users of the Service or the public</span></li>
              <li class="c0 li-bullet-0"><span class="c1">To protect against legal liability</span></li>
            </ul>
            <h2 class="c4" id="h.dvf2h9bnocio"><span class="c2">Security of Data</span></h2>
            <p class="c6"><span class="c1">The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</span></p>
            <h2 class="c4" id="h.dy68egcqtrtc"><span class="c2">Service Providers</span></h2>
            <p class="c6"><span class="c1">We may employ third party companies and individuals to facilitate our Service (&quot;Service Providers&quot;), provide the Service on our behalf, perform Service-related services or assist us in analyzing how our Service is used.</span></p>
            <p class="c6"><span class="c1">These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</span></p>
            <h2 class="c4" id="h.3qnozxtqzp5k"><span class="c2">Links to Other Sites</span></h2>
            <p class="c6"><span class="c1">Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party&#39;s site. We strongly advise you to review the Privacy Policy of every site you visit.</span></p>
            <p class="c6"><span class="c1">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</span></p>
            <h2 class="c4" id="h.wf8xco4bm8k1"><span class="c2">Children&#39;s Privacy</span></h2>
            <p class="c6"><span class="c1">Our Service does not address anyone under the age of 18 (&quot;Children&quot;).</span></p>
            <p class="c6"><span class="c1">We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</span></p>
            <h2 class="c4" id="h.xjgh052wrbqv"><span class="c2">Changes to This Privacy Policy</span></h2>
            <p class="c6"><span class="c1">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</span></p>
            <p class="c6"><span class="c1">We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the &quot;effective date&quot; at the top of this Privacy Policy.</span></p>
            <p class="c6"><span class="c1">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</span></p>
            <h2 class="c4" id="h.4co9bu6cipig"><span class="c2">Contact Us</span></h2>
            <p class="c6"><span class="c1">If you have any questions about this Privacy Policy, please contact us:</span></p>
            <ul class="c12 lst-kix_n0r0nx5rm29e-0 start">
              <li class="c0 li-bullet-0"><span class="c1">By email: info@receiptsiq.co</span></li>
            </ul>
            <p class="c14"><span class="c1"></span></p>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
