import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h1 class="c6" id="h.ao9dvqv549ov"><span class="c7">Terms and Conditions</span></h1>
            <p class="c1"><span class="c3">Last updated: July 13, 2022.</span></p>
            <p class="c1"><span class="c3">Please read these Terms and Conditions (&quot;Terms&quot;, &quot;Terms and Conditions&quot;) carefully before using the https://receiptsiq.co website and mobile application (the &quot;Service&quot;) operated by ReceiptsIQ.co (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;).</span></p>
            <p class="c1"><span class="c3">Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</span></p>
            <p class="c1"><span class="c4">Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</span></p>
            <h2 class="c0" id="h.j2kcye8uh474"><span class="c2">Intellectual Property</span></h2>
            <p class="c1"><span class="c3">All intellectual property on the App is owned by ReceiptsIQ.co or its licensors, which includes materials protected by copyright, trademark, or patent laws. All trademarks, service marks and trade names are owned, registered and/or licensed by ReceiptsIQ.co All content on the App (except for Personal Information), including but not limited to text, software, scripts, code, designs, graphics, photos, sounds, music, videos, applications, interactive features and all other content (&ldquo;Content&rdquo;) is a collective work under the Canada and other copyright laws and is the proprietary property of ReceiptsIQ.co; All rights reserved.</span></p>
            <h2 class="c0" id="h.xvf8h8atfl1f"><span class="c2">Registration</span></h2>
            <p class="c1"><span class="c3">We may require you to register in order to access certain features or services offered on or through the App (including setting up a user ID and password). Where you register with us, you must keep your registration and login details confidential. You will be responsible for any access to and all activity that occurs under your account on the App using your registration details, even if the access is by another person, or as a result of your failing to keep this information secure and confidential. You must be 18 years old to register.</span></p>
            <h2 class="c0" id="h.4hjoc95qzdrj"><span class="c2">App Use Restrictions</span></h2>
            <p class="c1"><span class="c3">You may use the Content only for your own non-commercial use to use the App. You agree not to change or delete any ownership notices from materials downloaded or printed from the App. You agree not to modify, copy, translate, broadcast, perform, display, distribute, frame, reproduce, republish, download, display, post, transmit or sell any Intellectual Property or Content appearing on the App, including Personal Information (defined below), without ReceiptsIQ.co&rsquo;s prior written consent, unless it is your own Personal Information that you legally post on the App. You agree not to use any data mining, robots, scraping or similar data gathering methods. Nothing in these Terms of Use shall be interpreted as granting any license of intellectual property rights to you.</span></p>
            <h2 class="c0" id="h.1wt2vtc450y3"><span class="c2">Links To Other Websites</span></h2>
            <p class="c1"><span class="c3">Our Service may contain links to third-party web sites or services that are not owned or controlled by us.</span></p>
            <p class="c1"><span class="c3">We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</span></p>
            <h2 class="c0" id="h.mujpfd5p7n1t"><span class="c2">Mobile Services</span></h2>
            <p class="c1"><span class="c3">The App contains services and features that are available to certain mobile Devices. Your carrier&rsquo;s normal rates and fees apply. Not all mobile services will work with all carriers or Devices. By using the ReceiptsIQ.co application, you agree that we may communicate with you by electronic means to your mobile Device and that certain information about your use of these services may be shared with us. If you change or deactivate your mobile phone number, you must promptly update your account information to ensure that we don&rsquo;t send your messages to a different person.</span></p>
            <h2 class="c0" id="h.tx7fc881up2i"><span class="c2">Indemnification</span></h2>
            <p class="c1"><span class="c3">You agree to indemnify, defend, and hold harmless ReceiptsIQ.co, its affiliates, officers, directors, employees, agents, licensors and suppliers from and against all claims, losses, liabilities, expenses, damages and costs, including, without limitation, attorneys&rsquo; fees, arising from or relating in any way to your Personal Information, your use of the App, your conduct in connection with the App or with other App users, or any violation of these Terms of Use, any law or the rights of any third party.</span></p>
            <h2 class="c0" id="h.idjwf0ohk8j9"><span class="c2">Warranty Disclaimer</span></h2>
            <p class="c1"><span>ReceiptsIQ.co</span><span class="c3">&nbsp;is not responsible or liable for any Personal Information posted on the App or for any offensive, unlawful or objectionable content you may encounter on or through the App. The App is provided &ldquo;AS IS&rdquo; and without warranties of any kind. To the fullest extent permitted by law, ReceiptsIQ.co disclaims all warranties, express or implied, including, but not limited to, implied warranties of title, merchantability, fitness for a particular purpose and non-infringement. ReceiptsIQ.co cannot guarantee and does not promise any specific results from use of the App. ReceiptsIQ.co does not represent or warrant that the App will be uninterrupted or error-free, that any defects will be corrected, or that this App or the server that makes the App available are free of viruses or anything else harmful. To the fullest extent permitted by law, ReceiptsIQ.co does not make any warranties or representations regarding the use of the materials or Content in the App in terms of their correctness, accuracy, adequacy, usefulness, reliability or otherwise. You understand and agree that you download or otherwise obtain Content at your own risk, and that you will be solely responsible for your use and any damage to your mobile Device, computer system or other Device in which you access the App, loss of data or other harm of any kind that may result. ReceiptsIQ.co reserves the right to change any and all Content and other items used or contained in the App at any time without notice. Some jurisdictions do not permit limitations or exclusions on warranties, so the above limitations may not apply to you.</span></p>
            <h2 class="c0" id="h.d61x3jloeoy4"><span class="c2">Contact Us</span></h2>
            <p class="c1"><span class="c3">If you have any questions about these Terms, please contact us at info@receiptsiq.co</span></p>
            <p class="c5"><span class="c3"></span></p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
