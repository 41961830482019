import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import DownloadApp from "components/cta/DownloadApp.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter.js";
import heroScreenshotImageSrc from "images/screenshots/receipts-list.png";
import taxTimeImage from "images/undraw_voting_nvu7.png";
import ocrImage from "images/undraw_File_searching_re_3evy.png";
import macHeroScreenshotImageSrc from "images/screenshots/receipt-details.png";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage disabled>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading id="receiptsiq-features">Features</Subheading>}
        heading={
          <>
            Expense Tracking Made <HighlightedText>Easy</HighlightedText>
          </>
        }
        description={"Take a photo of a receipt and we do the rest."}
      />
      <DownloadApp/>
      <MainFeature
        subheading={<Subheading>Friendly UI</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <MainFeature
        subheading={<Subheading>Peace of Mind</Subheading>}
        heading = {(
          <>
            Make things easier around <span tw="text-primary-500">tax</span> time
          </>
        )}
        description={"Don't be the person dropping over 1000 receipts on your accountant's desk come tax time. Use Receipts IQ to collect receipts for write-offs for your fiscal year."}
        imageSrc={taxTimeImage}
        imageBorder={false}
        imageDecoratorBlob={true}
        textOnLeft={false}
      />
      <MainFeature
        subheading={<Subheading>Technology</Subheading>}
        heading = {(
          <>
            Use <span tw="text-primary-500">OCR</span> to digitize your receipts, fast.
          </>
        )}
        description={"No need to manually enter the amount and categories again. Let Receipts IQ scan and digitize the receipt receipts. The app will automatically recognize text in the document and turn it into digital information in real-time. Automatic capture of transaction data, with high accuracy."}
        imageSrc={ocrImage}
        imageBorder={false}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
}
